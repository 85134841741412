<template>
  <v-container>
    <v-card>
      <v-card-title>Enter your payment information</v-card-title>
      <v-card-text>
        <template v-if="specificOrderSelected && specificOrderSelected.person && specificOrderSelected.person.contact && specificOrderSelected.person.contact.givenName && specificOrderSelected.person.contact.surname">
          <h5>Order for {{ specificOrderSelected.person.contact.givenName + ' ' + specificOrderSelected.person.contact.surname }}</h5>
        </template>
        <h6 v-if="specificOrderSelected && specificOrderSelected.final_price">Final Price: ${{ specificOrderSelected.final_price / 100 }} </h6>
        <br />
        <div ref="paymentCard" />
      </v-card-text>

      <v-card-actions>
        <v-btn
          v-if="paymentSubmittable"
          color="primary darken-1"
          text
          @click="updatePaymentInformation"
        >
          Submit
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
  import { mapActions, mapGetters, mapState } from 'vuex'
  const stripe = Stripe(
    'pk_live_51J4yHULmS5CcBkMzb8Epahll1kzy09X3T3chjV9Bd9ODJS1jteMXnGr98CC48e05Wvp4BYTF0VjyoX8bxG7yn9uC00FYfz48Nv',
  )

  const elements = stripe.elements()
  let card

  export default {
    name: 'PaymentRequest',
    data () {
      return {
        paymentSubmittable: true,
        order: {},
      }
    },
    computed: {
      ...mapGetters([
        'clientSecret',
        'specificOrderSelected',
      ]),
      // ...mapState(['specificOrderSelected']),
    },
    async created () {
      this.createNewPaymentIntent(this.$route.params.id)
      this.getRegistrantOrder(this.$route.params.id)
      this.generatePaymentForm()
    },
    methods: {
      ...mapActions([
        'getRegistrantOrder',
        'createNewPaymentIntent',
      ]),
      generatePaymentForm () {
        const options = {
          style: {
            base: {
              color: this.$vuetify.theme.dark ? '#fff' : '#000',
            },
          },
          hidePostalCode: true,
        }
        this.$nextTick(function () {
          card = elements.create('card', options)
          card.mount(this.$refs.paymentCard)
        })
      },
      updatePaymentInformation () {
        this.paymentSubmittable = false
        stripe.createToken(card).then(() => {
          stripe
            .confirmCardPayment(this.clientSecret, {
              payment_method: {
                card: card,
              },
            })
            .then(res => {
              if (res.error) {
                throw new Error(`Error - ${JSON.stringify(res.error)}`)
              }
              if (res.paymentIntent.status === 'requires_capture') {
                this.SET_PRIZEDESTINATIONDIALOG_STATE(true)
                this.paymentSubmittable = true
              }
            })
        })
      },
    },
  }
</script>

<style>

</style>
